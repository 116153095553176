import React from "react"
import * as styles from "./video.module.css"

const Video = ({ videoSrcURL, videoTitle, poster, ...props }) => (
  <div className="embed-responsive embed-responsive-16by9">
    <iframe
      className={`embed-responsive-item ${styles.iframe}`}
      poster={poster}
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      loading="lazy"

    />
    {/* <video controls autoPlay playsInline muted loop poster={poster}>
      <source
        src={videoSrcURL}
        type='video/mp4'
      />
    </video> */}
  </div>

)
export default Video



const opts = {
  width: '1180',
  height: '664',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    rel:0
  }
}